import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import yt from "../img/social/yt.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        className="footer has-text-white-ter"
        style={{ backgroundColor: "black" }}
      >
        <div
          className="content has-text-centered"
          style={{ backgroundColor: "black" }}
        >
          <div className="container">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4"></div>
              {/* begin flodesk form embed */}
              <div class="fd-ef-5ef8e144b9c153002b5ae927">
                <div class="ff__root">
                  <div class="ff__container">
                    <form
                      class="ff__form"
                      action="https://form.flodesk.com/submit"
                      method="post"
                      data-form="fdv2"
                      style={{ border: '1px solid white', padding: '20px 9px' }}
                    >
                      <h3 class="ff__title">
                        <div>
                          <p>
                            <span style={{ color: "#ffffff" }}>Subscribe</span>
                          </p>
                        </div>
                      </h3>
                      <div class="ff__subtitle">
                        <div>
                          <p>
                            <span style={{ color: "#ffffff" }}>
                              Sign up with your email address to receive news
                              and updates.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="ff__fields">
                        <input
                          type="text"
                          name="name"
                          value=""
                          style={{ display: "none" }}
                        />
                        <input
                          type="hidden"
                          name="submitToken"
                          value="68bc7984c0b0c1967b653c325b5c05732a540554d31af98a1f7344db09fa2bb19311ec64102b675c509bf65cc79e3636d324a4a5c0f8cd58874ada592c3e8aa3c551140dada0676f4270185f37856dfaa46882f6f1284771d4e90f4c58d27d2d"
                        />
                        <div class="ff__grid">
                          <div class="ff__cell">
                            <div class="fd-form-group">
                              <input
                                name="email"
                                class="fd-form-control ff__control"
                                placeholder="Email address"
                                style={{ marginTop: '20px', marginBottom: '20px' }}
                              />
                            </div>
                          </div>
                          <div class="ff__footer">
                            <button
                              type="submit"
                              class="fd-btn ff__button"
                              data-form-el="submit"
                              className="btn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <img
                  height="1"
                  width="1"
                  style={{ display: "none" }}
                  src="https://t.flodesk.com/utm.gif?r=5ef8e144b9c153002b5ae927"
                />
              </div>
            </div>
          </div>
        </div>
        {/* end flodesk form embed */}

        <div
          className="content has-text-centered"
          style={{ backgroundColor: "black" }}
        >
          <div className="container">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <p className="menu-list-header">GET NOTICED</p>
                    <li>
                      <a
                        href="https://www.vfiles.com/runway"
                        className="navbar-item footer-item"
                      >
                        VFILES Runway
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.vfiles.com/loud"
                        className="navbar-item footer-item"
                      >
                        VFILES Loud
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <p className="menu-list-header">QUESTIONS</p>
                    <li>
                      <a
                        className="navbar-item footer-item"
                        href="https://www.vfiles.com/faqs"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        className="navbar-item footer-item"
                        href="https://www.vfiles.com/privacy"
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        className="navbar-item footer-item"
                        href="https://www.vfiles.com/partnerships"
                      >
                        Partnerships
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <p className="menu-list-header">VFILES GALAXY</p>
                    <div style={{ display: "flex" }}>
                      <a
                        title="facebook"
                        href="https://www.facebook.com/thevfiles"
                      >
                        <img
                          src={facebook}
                          alt="Facebook"
                          style={{ width: "1em", height: "1em" }}
                        />
                      </a>
                      <a title="twitter" href="https://twitter.com/vfiles">
                        <img
                          className="fas fa-lg"
                          src={twitter}
                          alt="Twitter"
                          style={{ width: "1em", height: "1em" }}
                        />
                      </a>
                      <a title="instagram" href="https://instagram.com/vfiles">
                        <img
                          src={instagram}
                          alt="Instagram"
                          style={{ width: "1em", height: "1em" }}
                        />
                      </a>
                      <a
                        title="vimeo"
                        href="https://www.youtube.com/user/vfilesvideo"
                      >
                        <img
                          src={yt}
                          alt="yt"
                          style={{ width: "1em", height: "1em" }}
                        />
                      </a>
                    </div>
                    <li>
                      <a
                        href="https://www.vfiles.com/contact"
                        className="navbar-item footer-item"
                      >
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        className="navbar-item footer-item"
                        href="https://www.vfiles.com/terms"
                      >
                        Terms
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
